.block-clients {
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-xsmall);
    --padding-v-top: var(--padding-xxl);
    --padding-v-bottom: var(--padding-xl);
    --padding-h: var(--padding-xxl);
    --gap: var(--padding-l);
    
    .scrabble-letter {
        --font-size-letter: var(--font-size-xsmall);
        --image-size: #{rem(460)};
        --letter-size: 100%;
    }
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-medium);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xs);
        
        .scrabble-letter {
            --letter-size: 80vw;
        }
    }
}

.block-clients {
    @include font-sans();
    font-size: var(--font-size);

    position: relative;
    width: 100vw;

    .title {
        @include font-sans();
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-xs);
    }

    .text {
        max-width: rem(525);

        p:not(:last-child) {
            margin-bottom: var(--padding-xxs);
        }
    }

    .holder {
        display: flex;
        height: 100%;
        width: auto;
    }
    
    .slide {
        color: var(--primary-color);
        align-items: center;
        display: flex;
        justify-content: center;
        transition: color .3s ease-out;
        pointer-events: none;
        
        .wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            gap: var(--padding-small);
        }

        .scrabble-letter {
            --color: var(--primary-color);
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            text-align: left;
            text-transform: initial;
            
            min-height: var(--letter-size);
            height: auto;
            width: var(--letter-size);

            figure {
                margin-bottom: var(--padding-xs);
            }

            .text {
                margin-bottom: var(--padding-xs);
            }

            .name {
                text-transform: uppercase;
            }

            .position {
                color: var(--grey);
            }
        }
    }

    // .slide,
    // [data-scroll-sticky]:first-child {
    //     padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    // }
    
    @media (max-width: $smartphone) {
        // padding: var(--padding-v) 0;

        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .slide {
            &:first-child { display: none; }

            scroll-snap-align: start;

            padding-bottom: 0;
            padding-top: 0;

            .wrapper {
                height: 100%;
            }

            .scrabble-letter {
                height: 100%;

                .text {
                    flex-grow: 1;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        .slide,
        [data-scroll-sticky]:first-child {
            padding: 0 var(--padding-h);
        }

        [data-scroll-sticky]:first-child {
            margin-bottom: var(--padding-s);
        }
    }
    
    @media (min-width: $smartphone) {
        height: var(--height);

        // .slide,
        // [data-scroll-sticky]:first-child {
        //     padding: var(--padding-v) var(--padding-h);
        // }

        .slide,
        [data-scroll-sticky]:first-child {
            padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
        }
        
        > div {
            height: 100vh;
            position: relative;
            width: 100vw;
            overflow: hidden;
        }

        > div:first-child {
            height: 50vh;
        }
        
        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }

        .slide:first-child { width: 100vw; }

        .slide {
            .wrapper {
                margin-top: 20vh;
            }
        }
    }
}