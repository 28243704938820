.block-story-slider {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-xs);
    --gap: var(--padding-xs);
    --width-slide: #{rem(255)};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxs);
        --width-slide: #{rem(155)};
    }
}

.block-story-slider {
    width: 100%;
    margin: var(--padding-v) 0;
    padding: 0 var(--padding-h);
    user-select: none;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    @include z-index(1);

    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }
    }

    &__holder {
        order: 1;
        position: relative;
        min-width: 0;
        display: flex;
        flex-wrap: nowrap;
        gap: var(--gap);
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        height: 100%;
        overflow: hidden;

        &:first-child {
            --width-slide: 15vw;
        }

        img,
        video {
            object-fit: cover;
            object-position: center;
            border-radius: var(--radius-sm);

            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }

        figure {
            border-radius: var(--radius-sm);
            overflow: hidden;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: var(--padding-s);
        margin-bottom: var(--padding-xxxs);
    }

    @media (max-width: $smartphone) {
        padding-left: 0;
        padding-right: 0;

        &__holder {
            padding-left: var(--padding-h);
            @include scroll-horizontal();
        }

        &__item {
            &:last-of-type {
                margin-right: var(--padding-h);
            }
        }

        &__footer {
            display: none;
        }

        &__item:first-child {
            display: none;
        }
    }
}
