.block-credits {
    --font-size-title: var(--font-size-base);
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    --padding-line: var(--padding-xsmall);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --padding-line: var(--padding-xxs);
    }
}

.block-credits {
    @include font-sans();
    font-size: var(--font-size);
    position: relative;
    padding: var(--padding-v) var(--padding-h);

    .wrapper {
        border-top: 1px solid var(--primary-color);
    }

    .title {
        @include font-sans();
        font-size: var(--font-size-title);
    }

    li {
        position: relative;
        border-bottom: 1px solid var(--grey);
        padding: var(--padding-line) 0;

        .job {
            text-align: left;
        }

        .name {
            text-align: right;
        }

        .underline {
            background-color: var(--primary-color);
            position: absolute;
            bottom: -1px;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: center left;
        }
    }

    @media (max-width: $smartphone) {
        .wrapper {
            padding-top: var(--padding-line);
        }
     
        .title {
            margin-bottom: var(--padding-xs);
        }

        li {
            border-bottom: 1px solid var(--primary-color);

            .underline {
                display: none;
            }
        }

        &__list:first-child {
            li {
                display: grid;
                grid-template-areas: 
                    "b a";
                grid-template-columns: 50% 50%;
            }

            .job {
                grid-area: b;
            }

            .name {
                grid-area: a;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .title {
            padding-top: var(--padding-line);
        }
        
        &__list {
            li {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
