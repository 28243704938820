@mixin button {
    --color: var(--primary-color);
    --font-size: var(--font-size-base);

    align-items: center;
    appearance: none;
    cursor: pointer;
    color: var(--color);
    position: relative;
    text-align: center;
    vertical-align: top;

    @include basic-a();
    @include font-sans(1);
    font-size: var(--font-size);
    color: inherit;
    padding: var(--padding-xs) var(--padding-s);
    border-radius: var(--radius);
    backdrop-filter: blur(5px);
    position: relative;
    min-width: rem(120);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    
    .overlay {
        border-radius: var(--radius);
        border: 1px solid var(--primary-color);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        transform: scale3d(1.1, 1.1, 1);
        // opacity: 0;
        transition: opacity .1s ease-out, transform .3s ease-out;
    }

    @include isCursor() {
        &:hover {
            text-decoration: none;

            @include font-serif(1);

            .text {
                margin-top: rem(2);
            }
            
            .overlay {
                opacity: 1;
                transform: scale3d(1, 1, 1);
            }
        }
    }
}

.button {
    @include button;
}