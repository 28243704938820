#Preloader {
    --font-size: var(--font-size-xxlarge);
    --color: var(--cream);
    --bg: var(--black);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    --logo: #{rem(275)};
    
    @media (max-width: $smartphone) {
        --font-size: #{rem(42px)};
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --logo: #{rem(200)};
    }

    @media (min-width: $xlargeScreen) {
        --logo: #{rem(200)};
    }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    
    .overlay {
        @include z-index(0);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 25%;
        background-color: var(--bg);

        &:nth-child(2) { left: 25%; }
        &:nth-child(3) { left: 50%; }
        &:nth-child(4) { left: 75%; }
    }
    
    .wrapper {
        @include z-index(1);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        
        align-items: center;
        display: flex;
        justify-content: center;
        
        color: var(--color);
        // background-color: var(--bg);
        
        &.--first {
            @include z-index(2);

            div {
                width: var(--logo);
            }
        }
        
        &.--second {
            opacity: 0;
            padding: var(--padding-v) var(--padding-h);
        }
        
        .text {
            @include font-sans();
            font-size: var(--font-size);
            text-align: center;

            strong {
                color: var(--aux-1);
            }

            em {
                color: var(--aux-2);
            }

            strong,
            em {
                @include font-serif();
            }

            .line {
                padding: 0 rem(20);
            }
        }
    }

    @media (max-width: $smartphone) {
        .overlay {
            width: 50%;

            &:nth-child(2) { left: 50%; }
        }
    }
}
