.widget-landing {
    --gap: var(--padding-l);
    
    --padding-v: 0; 
    --padding-h: var(--padding-m);
    
    --height: 100vh;

    --visor: #{rem(360)};
    --scrabble-size: #{rem(710)};
        
    --font-size: var(--font-size-base);

    @media (max-width: $smartphone) {
        --padding-v: 15vw;
        --gap: var(--padding-s);
        --padding-h: 0;
        --scrabble-size: #{rem(360)};
    
        --font-size: var(--font-size-xsmall);
    }
}

.widget-landing {
    user-select: none;
    width: 100vw;
    overflow: hidden;
    height: var(--height);
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    align-items: center;
    justify-content: center;
    
    .list {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
 
        .item {
            border-bottom: 2px solid red;

            padding: calc(var(--letter-size) / 2) 0;

            @include font-sans();
            font-size: var(--font-size-xxlarge);

            .name {
                @include basic-a();
                display: flex;
            }
        }
    }

    .scrabble-board {
        display: grid;
        grid-template-areas: 
            "y y y y p1 q q q q q"
            "y y y y o1 q q q q q"
            "y y y y r1 q q q q q"
            "a1 b1 o2 u1 t1 q q q q q"
            "z z z z f1 q q q q q"
            "x x x c1 o3 n1 t2 a2 c2 t3"
            "k k k k l1 d d d d d"
            "k k k k i1 d d d d d"
            "k k k k o4 d d d d d"
        ;

        gap: var(--char-gap);
        width: var(--scrabble-size);
        
        opacity: 0;
        pointer-events: none;
    }

    .letter {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000; 
        font-size: 30px; 
        height: var(--char-size);
        width: var(--char-size);
    }

    @media (max-width: $smartphone) {
        height: calc(var(--vh, 1vh) * 100);
        max-height: 100%;

        .scrabble-board {
            margin-right: -42vw;
            margin-top: -20vh;
        }
    }
}

#sidebar-text {
    @include font-sans();
    font-size: var(--font-size);

    position: absolute;
    top: 0;
    right: var(--padding-l);
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    text-align: right;

    opacity: 0;

    p:first-child {
        margin-bottom: var(--padding-xl);
    }

    strong {
        font-weight: 400;
        color:var(--aux-1);
    }

    a {
        @include basic-a();
        color: var(--aux-2);
        text-decoration: none;
    }

    @include isCursor() {
        a:hover {
            @include font-serif(1);
        }
    }

    @media (max-width: $smartphone) {
        --font-size: #{rem(30)};

        @include z-index($z-index-windows);

        position: fixed;
        top: 5vh;
        left: 5vw;
        width: 90vw;
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        max-height: 90%;

        // background: var(--black30);
        background: rgba(#fffbef, .6);
        right: auto;
        z-index: 35;
        border-radius: var(--radius);
        padding: 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        p:first-child {
            margin-bottom: var(--padding-xs);
        }

        &::before {
            border-radius: var(--radius);
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#f5945f, .2);
        }
    }
}
