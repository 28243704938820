:root {
    --white: #ffffff;
    --black: rgb(31, 36, 36);
    --black30: rgba(31, 36, 36, .3);
    --grey: #969696;

    --brown: #33271E;
    --cream: #fffbef;

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;

    --aux-1: #f5945f;
    --aux-2: #14a697;
    --aux-3: #0d6073;
}