#Sidemenu {
    --color: var(--cream);
    --bg: var(--black);
    --padding-v: calc(var(--header-height) + var(--padding-xs));
    --padding-h: 0; //var(--padding-xs);
    --font-size: var(--font-size-xxlarge);
}

#Sidemenu {
    @include z-index($z-index-sidemenu);

    color: var(--color);
    background-color: var(--bg);
    padding: var(--padding-v) var(--padding-h);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--padding-xxs);

    padding: 0 var(--padding-xs);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .link-arrow {
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size);
        color: inherit;
        border-radius: var(--radius-l);
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--padding-xxs) 0;
        transition: width .2s ease-out;
        
        .overlay {
            border-radius: var(--radius-l);
            border: 1px solid var(--color);
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;

            transform: scale3d(1.1, 1.1, 1);
            opacity: 0;
            transition: opacity .1s ease-out, transform .3s ease-out;
        }

        &[aria-current="page"] {
            @include font-serif(1);
            

            width: 100%;

            .text {
                margin-top: rem(12);
            }
            
            .overlay {
                opacity: 1;
                transform: scale3d(1, 1, 1);
            }
        }
    }
}