.block-image {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-xl);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
    }
    
    &.--no-padding {
        --padding-h: 0;
    }
}

.block-image {
    padding: var(--padding-v) var(--padding-h);
}
