.block-text {
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-text {
    @include font-sans();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    
    .text {
        max-width: rem(860);
        margin: auto;
    }
}