.widget-projects {
    --font-size: var(--font-size-xxlarge);
    --gap: var(--padding-l);
    
    --padding-v: 15vw;
    --padding-h: var(--padding-m);
    
    --width: #{rem(1200)};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxsmall);
        --gap: var(--padding-s);
        --padding-h: var(--padding-xsmall);
        --width: 100%;
        --padding-v: 25vh;
    }
}

.widget-projects {
    user-select: none;
    width: 100vw;
    overflow: hidden;
    pointer-events: none;
    
    > div {
        padding: 0 var(--padding-h);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }

    &__empty {
        height: var(--padding-v);
    }

    &__item {
        pointer-events: none !important;
        
        @include font-sans();
        font-size: var(--font-size);
        
        margin-bottom: var(--padding-m);
        width: var(--width);

        a {
            pointer-events: none;
            visibility: hidden;
        }
    }


    @media (max-width: $smartphone) {
        > div {
            margin-left: 20vw;
        }

        &__item {
            margin-bottom: var(--padding-small);
        }

        &__empty {
            &.--first {
                height: calc(var(--padding-v) / 3 * 2);
            }
        }
    }
}
