.block-video-text {
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-xl);
    --font-size: var(--font-size-base);
    --gap: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-m);
    }
}

.block-video-text {
    @include font-sans();
    font-size: var(--font-size);

    padding: var(--padding-v) var(--padding-h);

    .text p {
        margin-bottom: var(--padding-xs);
    }

    @media (max-width: $smartphone) {
        .video {
            width: rem(210);
            margin: auto;
            margin-bottom: var(--gap);
        } 
    }
    
    @media (min-width: $smartphone) {
        align-items: flex-start;
        justify-content: center;
        display: flex;
        gap: var(--gap);
        margin: 25vh 0 0;

        .video {
            flex: 1 0 rem(282);
        } 

        .text {
            flex-grow: 1;
        }
    }
}
