.image-visor {
    --padding-h: var(--padding-m);
    --visor: #{rem(360)};
    --visor-gap: var(--padding-xxs);

    &.--sm {
        --visor: #{rem(240)};
    }
    
    @media (max-width: $smartphone) {
        --padding-h: 0;
        --height: 100%;
        --visor-gap: var(--padding-xxxs);

        &.--sm {
            --visor: #{rem(150)};
        }
    }
}

.image-visor {
    border-radius: var(--radius);
    width: var(--visor);
    height: var(--visor);
    position: relative;

    &.--fixed {
        position: fixed;
        top: 50%;
        right: calc(2 * var(--padding-h));
        transform: translate3d(0, -50%, 0);
    }

    &__image {
        width: var(--visor);
        height: var(--visor);
        
        img {
            width: var(--visor);
            height: var(--visor);
            border-radius: var(--radius-sm);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .visor {
        display: block;
        border-radius: var(--radius);
        border: 1px solid var(--primary-color);
        transform: translate3d(var(--visor-gap), calc(-1 * var(--visor-gap)), 0);
        position: absolute;
        top: 0;
        right: 0;

        height: calc(var(--visor) + 2 * var(--visor-gap));
        width: calc(var(--visor) + 2 * var(--visor-gap));

        .counter {
            @include font-sans();
            font-size: var(--font-size-base);

            position: absolute;
            bottom: 10%;
            right: 10%;
        }
    }

    .scrollbar {
        position: absolute;
        bottom: 0; //calc(-1 * var(--visor-gap));
        left: calc(100% + 2 * var(--visor-gap));
    }

    @media (max-width: $smartphone) {
            display: none;
    }
}
