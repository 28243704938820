.next-project {
    --font-size: var(--font-size-medium);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-l);
    --gap: var(--padding-m);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xs);
    }
}

.next-project {
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    text-transform: uppercase;
    
    a {
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size);
        color: var(--primary-color);
        
        .label {
            @include font-serif(1);
            margin-bottom: var(--padding-xxxs);
        }

        > div:first-child {
            text-align: center;
        }
    }

    @include isCursor() {
        &:hover {
            a {
                opacity: .3;
            }

            a:hover {
                opacity: 1;
            }
        }
    }

     @media (max-width: $smartphone) {
        padding: var(--padding-v) var(--padding-h);
        gap: var(--gap);

        .image-visor {
            margin-top: var(--padding-xs);
            display: block !important;
        }
    }

    @media (min-width: $smartphone) {
        a {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            gap: var(--padding-small);
            padding: var(--padding-v) 0;
            width: 50%;
            transition: opacity .2s ease-out;

            &:first-child {
                flex-direction: row-reverse;
                padding-left: var(--padding-h);
            }
            
            &:last-child {
                padding-right: var(--padding-h);
            }
        }

        .image-visor {
            margin-top: var(--padding-xxs);
        }
    }
}
