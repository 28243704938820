@use "sass:math";

:root {
    --y-header: 0;

    --padding-xxxxl: #{math.div(360px, 16px) * 1rem};
    --padding-xxxl: #{math.div(240px, 16px) * 1rem};
    --padding-xxl: #{math.div(180px, 16px) * 1rem};
    --padding-xl: #{math.div(120px, 16px) * 1rem};
    --padding-l: #{math.div(80px, 16px) * 1rem};
    --padding-m: #{math.div(70px, 16px) * 1rem};
    --padding-s: #{math.div(40px, 16px) * 1rem};
    --padding-small: #{math.div(30px, 16px) * 1rem};
    --padding-xs: #{math.div(20px, 16px) * 1rem};
    --padding-xsmall: #{math.div(15px, 16px) * 1rem};
    --padding-xxs: #{math.div(10px, 16px) * 1rem};
    --padding-xxxs: #{math.div(5px, 16px) * 1rem};

    --header-height: #{math.div(117px, 16px) * 1rem};
    
    --scrollbar-height: #{math.div(4px, 16px) * 1rem};
    --scrollbar-width: #{math.div(560px, 16px) * 1rem};
    
    --radius-l: #{math.div(50px, 16px) * 1rem};
    --radius: #{math.div(40px, 16px) * 1rem};
    --radius-sm: #{math.div(36px, 16px) * 1rem};
    
    --border: #{math.div(3px, 16px) * 1rem};
    
    --letter-size: #{math.div(100px, 16px) * 1rem};
    
    --char-size: #{math.div(60px, 16px) * 1rem};
    --char-gap: #{math.div(12px, 16px) * 1rem};
    --word-gap: #{math.div(35px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --header-height: #{math.div(87px, 16px) * 1rem};

        --scrollbar-width: #{math.div(200px, 16px) * 1rem};
        --border: #{math.div(2px, 16px) * 1rem};
        --radius: #{math.div(20px, 16px) * 1rem};
        --radius-sm: #{math.div(18px, 16px) * 1rem};

        --char-size: #{math.div(30px, 16px) * 1rem};
        --char-gap: #{math.div(6px, 16px) * 1rem};
        --word-gap: #{math.div(10px, 16px) * 1rem};
    }
}
