@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --bg: transparent;
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-l);
    --font-size: var(--font-size-xsmall);
    --logo-width: #{rem(143)};
    --logo-height: #{rem(77)};

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --logo-width: #{rem(87)};
        --logo-height: #{rem(47)};
    }

    .--sidemenu & {
        --color: var(--cream);
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    .logo {
        @include basic-a();
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: var(--logo-height);
            width: var(--logo-width);

            // path {
                fill: var(--color);
            // }
        }
    }

    .toggle {
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size);
        color: inherit;
        padding: var(--padding-xxs) var(--padding-xs);
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background-color: transparent;
        // backdrop-filter: blur(5px);
        border-radius: var(--radius);

        position: relative;
        right: calc(-1 * (var(--padding-xs)));
    }

    .link-arrow {
        @include basic-a();
        @include font-sans(1);
        font-size: var(--font-size);
        color: inherit;
        padding: var(--padding-xxs) var(--padding-xs);
        border-radius: var(--radius);
        backdrop-filter: blur(5px);
        position: relative;
        min-width: rem(120);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .overlay {
            border-radius: var(--radius);
            border: 1px solid var(--primary-color);
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;

            transform: scale3d(1.1, 1.1, 1);
            opacity: 0;
            transition: opacity .1s ease-out, transform .3s ease-out;
        }

        @include isCursor() {
            &:not(.target):hover {
                @include font-serif(1);

                .text {
                    margin-top: rem(2);
                }
                
                .overlay {
                    opacity: 1;
                    transform: scale3d(1, 1, 1);
                }
            }
        }
        
        &[aria-current="page"] {
            @include font-serif(1);

            .text {
                margin-top: rem(2);
            }
            
            .overlay {
                opacity: 1;
                transform: scale3d(1, 1, 1);
            }
        }
    }
    
    nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--padding-xs);

        margin-right: calc(-1 * var(--padding-xs));
    }

    @media (max-width: $smartphone) {
        align-items: flex-start;

        nav {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        .toggle {
            display: none;
        }
    }
}

#Token {
    --padding-v: var(--padding-small);
    --padding-h: var(--padding-l);
    --logo-width: #{rem(102)};
    --logo-height: #{rem(121)};
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --logo-width: #{rem(69)};
        --logo-height: #{rem(82)};
    }

    @include basic-a();
    @include z-index($z-index-footer);
    background-color: transparent;
    padding: 0;
    
    position: fixed;
    right: var(--padding-h);
    bottom: var(--padding-v);
    width: var(--logo-width);
    height: var(--logo-height);

    opacity: 0;
    pointer-events: none;
    
    svg {
        width: 100%;
        height: 98%;
        // stroke: var(--black);
    }

    span,
    svg {
        pointer-events: none;
    }

    span {
        display: none;

        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--aux-2);
        color: var(--cream);
        padding: var(--padding-xxxs) var(--padding-xxs);

        @include font-sans();
        font-size: var(--font-size-xxxsmall);

        width: 130%;
        border-radius: rem(4);
    }

    @media (max-width: $smartphone) {
        span {
            width: 190%;
        }
    }

    &.--active {
        span {
            display: block;
        }

        svg .color {
            fill: var(--aux-2);
        }
    }
}

#Letterbag {
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-l);
    --logo-width: #{rem(102)};
    --logo-height: #{rem(169)};
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --logo-width: #{rem(68)};
        --logo-height: #{rem(113)};
    }

    @include z-index($z-index-footer);

    position: fixed;
    left: var(--padding-h);
    bottom: var(--padding-v);
    width: var(--logo-width);
    height: var(--logo-height);

    opacity: 0;
    pointer-events: none;

    svg {
        height: 100%;
        width: 100%;
    }
}
