.block-images-slider {
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-l);
    --gap: var(--padding-l);
    --height: #{rem(477)};
    // --width-slide: #{rem(777)};
    --width-slide: auto;
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-s);
        --height: #{rem(150)};
        // --width-slide: #{rem(245)};
    }
}

.block-images-slider {
    @include z-index(1);
    @include font-sans();
    font-size: var(--font-size);

    padding: var(--padding-v) 0;
    width: 100%;
    overflow: hidden;

    position: relative;
    display: flex;
    flex-direction: column;

    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: grab;    
        
        &:active {
            cursor: grabbing;
        }
    }

    &__holder {
        order: 1;
        position: relative;
        height: var(--height);
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--gap);
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        height: var(--height);
        overflow: hidden;

        display: flex;
        justify-content: center;
        
        figure {
            position: relative;
        }

        svg {
            height: 100%;
            width: auto;
        }

        img,
        video {
            height: 100%;
            width: 100%;
            width: auto;

            // position: absolute;
            // top: 0;
            // left: 0;

            // object-fit: cover;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: var(--padding-s);
        padding: 0 var(--padding-h);

        > div:first-child,
        > div:nth-child(2) {
            flex: 0 0 35%;
        }

        span {
            height: calc(1.24 * var(--font-size));
        }

        .numbers {
            display: flex;
            align-items: center;
            
            .indicator {
                align-items: flex-end;
            }
        }

        .indicator {
            display: inline-flex;
            flex-direction: column;
            overflow: hidden;
            
            > span {
                display: inline-flex;
                transform: translate3d(0, calc(-1 * 100% * var(--current)),0);
                transition: transform .1s linear;
            }
        }
        
        .controls {
            display: flex;
            justify-content: flex-end;
            gap: var(--padding-small);
            flex-grow: 1;
            
            button {
                padding: 0;
                pointer-events: all;
                @include basic-a();
                background-color: transparent;
                height: rem(24);
                width: rem(24);

                svg {
                    height: rem(24);
                    width: rem(24);
                    fill: var(--primary-color);
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        // padding: 0 var(--padding-h);

        &__footer {
            display: none;
        }

        &__item:first-child,
        &__item:last-child {
            display: none;
        }

        padding-left: 0;
        padding-right: 0;

        &__holder {
            padding-left: var(--padding-h);
            @include scroll-horizontal();
        }

        &__item {
            &:last-of-type {
                margin-right: var(--padding-h);
            }
        }
    }

    @media (min-width: $smartphone) {
        // &__item {
        //     padding: 0 var(--padding-h);
        // }

        &__item:first-child,
        &__item:last-child  {
            --width-slide: 0;
        }

        &__item:first-child,
        &__item:nth-child(2),
        &__item:nth-last-child(2),
        &__item:last-child  {
            padding: 0;
        }
    }
}
