.word {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--word-gap);
    
    @include basic-a();
    
    > div {
        display: flex !important;
        align-items: flex-start;
        justify-content: flex-start;
        gap: var(--char-gap);
    }

    .letter {
        background-color: lightblue;
        height: var(--char-size);
        min-width: var(--char-size);
        text-align: center;
    }
}
