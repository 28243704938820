.block-title-text {
    --font-size-big: var(--font-size-large);
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-base);
        --font-size: var(--font-size-xsmall);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
    }
}

.block-title-text {
    @include font-sans();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    
    .title {
        @include font-sans();
        font-size: var(--font-size-big);
    }

    @media (max-width: $smartphone) {
        .title {
            margin-bottom: var(--padding-xxs);
        }
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--padding-m);

        .text {
            flex: 1 0 rem(441);
        }
        
        .title {
            flex-grow: 1;
        }
    }
}