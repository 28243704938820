.block-highlight {
    --font-size: var(--font-size-xxlarge);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.block-highlight {
    padding: var(--padding-v) var(--padding-h);
    
    .title {
        @include font-sans();
        font-size: var(--font-size);
        color: var(--aux-2);
        text-align: center;
        max-width: rem(980);
        margin: auto;
    }
}
