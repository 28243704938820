.scrabble-letter {
    --color: var(--primary-bg);
    --padding: var(--padding-small);
    
    @media (max-width: $smartphone) {
        --padding: var(--padding-xs);
    }
}

.scrabble-letter {
    text-transform: uppercase;
    text-align: center;
    color: var(--color);
    padding: var(--padding);

    @include font-sans();
    font-size: var(--font-size-letter);

    transition: color .3s ease-out;

    backdrop-filter: blur(5px);

    span {
        text-align: right;
    }
    
    sub {
        font-size: var(--font-size-letter-sub);
        margin-left: rem(5);
    }

    &:not(.--full) {
        margin-right: rem(5);
    }

    &.--full {
        border: var(--border) solid var(--color);
        border-radius: var(--radius);
        height: var(--letter-size);
        width: var(--letter-size);
        
        align-items: center;
        display: flex !important;
        justify-content: center;
        
        position: relative;

        sub {
            position: absolute;
            bottom: var(--padding-small);
            right: var(--padding-small);
        }
    }
}
