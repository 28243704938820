.block-what-i-do {
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-xl);
    --gap: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-medium);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-m);
    }
}

.block-what-i-do {
    @include font-sans();
    font-size: var(--font-size);
    
    padding: var(--padding-v) var(--padding-h);
    
    .title {
        @include font-sans();
        font-size: var(--font-size-title);
    }

    .jobs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $smartphone) {
        .title {
            margin-bottom: var(--padding-xs);
        }
    }
    
    @media (min-width: $smartphone) {
        align-items: flex-start;
        justify-content: space-between;
        display: flex;
        gap: var(--gap);
        
        .jobs {
            width: 60%;
            grid-template-columns: repeat(4, 1fr);
            gap: var(--padding-xs);
        } 
    }
}
