.block-experience {
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    --padding-line: var(--padding-xsmall);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-medium);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --padding-line: var(--padding-xxs);
    }
}

.block-experience {
    @include font-sans();
    font-size: var(--font-size);

    position: relative;

    padding: var(--padding-v) var(--padding-h);

    .title {
        @include font-sans();
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-xs);
        color: var(--aux-2);
    }

    &__list {
        p {
            font-size: var(--font-size-base);
            margin-bottom: var(--padding-small);
        }

        li {
            position: relative;
            border-bottom: 1px solid var(--grey);
            padding: var(--padding-line) 0;
    
            .role,
            .what {
                text-align: left;
            }
    
            .year,
            .where {
                text-align: right;
            }
    
            .underline {
                background-color: var(--primary-color);
                position: absolute;
                bottom: -1px;
                left: 0;
                height: 1px;
                width: 100%;
                transform-origin: center left;
            }
        }
    }


    @media (max-width: $smartphone) {
        li {
            border-bottom: 1px solid var(--primary-color);

            .underline {
                display: none;
            }
        }

        &__list:first-child {
            li {
                display: grid;
                grid-template-areas: 
                    "b a"
                    "b c";
                grid-template-columns: 40% 60%;
            }

            .role {
                grid-area: b;
            }

            .company {
                grid-area: a;
                text-align: right;
            }

            .year {
                grid-area: c;
            }
        }

        &__list:last-child {
            li {
                display: grid;
                grid-template-areas: 
                    "b a";
            }

            .what {
                grid-area: b;
            }

            .where {
                grid-area: a;
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .wrapper {
            display: flex;
            gap: var(--padding-m);
        }

        &__list {
            flex-grow: 1;

            ul {
                li {
                    display: flex;
                    justify-content: space-between;

                    .company {
                        text-align: center;
                        min-width: 35%;
                    }

                    .role {
                        min-width: 40%;
                    }

                    .year {
                        min-width: 25%;
                    }
                }
            }
        }
    }
}