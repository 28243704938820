.block-text-corners {
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-xl);
    --font-size: var(--font-size-xxlarge);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --font-size: var(--font-size-medium);
    }
}

.block-text-corners {
    @include font-sans();
    font-size: var(--font-size);

    padding: var(--padding-v) var(--padding-h);

    .text:first-child {
        text-align: left;
        margin-bottom: var(--padding-xl);
    }

    .text:last-child {
        text-align: right;
    }

    @media (max-width: $smartphone) {
        .text:first-child {
            margin-bottom: var(--padding-l);
        }
    }
}
