.widget-contact {
    --font-size: var(--font-size-xxlarge);
    --font-size-bottom: var(--font-size-xsmall);
    --padding-v-top: calc(var(--header-height) + var(--padding-xs));
    --padding-v-bottom: var(--padding-m);
    --padding-h: var(--padding-m);
    --color: var(--primary-color);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);
        --font-size-bottom: var(--font-size-xxxsmall);
        // --padding-h: var(--padding-xs);
        --padding-v-top: calc(var(--header-height) + var(--padding-xxs));
        --padding-v-bottom: var(--padding-xl);
    }
}

.widget-contact {
    @include font-sans();

    position: relative;
    padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    color: var(--color);
    min-height: 100dvh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    
    a {
        @include basic-a();
        color: var(--color);

        @include isCursor() {
            &:not(.button):hover {
                text-decoration: underline;
            }
        }
    }

    .top {
        font-size: var(--font-size);

        strong {
            color: var(--aux-1);
        }

        em {
            color: var(--aux-2);
        }

        strong,
        em {
            @include font-serif(1);
        }
    }

    .bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--padding-xs);
        margin-top: var(--padding-xs);
        font-size: var(--font-size-bottom);
        
        .link {
            text-transform: uppercase;
        }
    }
        
    @media (min-width: $smartphone) {
        .bottom {
            gap: var(--padding-m);
            margin-top: var(--padding-s);
            font-size: var(--font-size-bottom);
        }
    }
}
