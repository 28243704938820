.block-clients-list {
    --font-size: var(--font-size-large);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    --gap: var(--padding-m);
    --image-width: #{rem(523)};
    --image-height: #{rem(54)};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxs);
        --image-width: #{rem(89)};
        --image-height: #{rem(32)};
    }
}

.block-clients-list {
    @include font-sans();
    font-size: var(--font-size);

    position: relative;
    width: 100vw;
    overflow: hidden;

    padding: var(--padding-v) var(--padding-h);
    
    li {
        
        a {
            @include basic-a();
            display: flex;
            gap: var(--gap);
            justify-content: space-between;
            text-decoration: none;
            color: var(--primary-color);
        }
    }

    .name {
        text-align: left;
        flex-grow: 1;
    }

    .year {
        text-align: right;
    }

    .image {
        height: var(--image-height);
        width: var(--image-width);

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    @media (max-width: $smartphone) {
        // padding-top: 0;

        .year { display: none; }

        li:not(:last-child) {
            margin-bottom: var(--padding-xxs);
        }
    }
    
    @media (min-width: $smartphone) {
        li:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }

        .image {
            height: var(--image-height);
            width: var(--image-width);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            };
        }
    
        @include isCursor() {
            .image,
            .year {
                opacity: 0;
                transition: opacity .15s ease-out;
            }
            
            li:hover {
                .image,
                .year {
                    opacity: 1;
                    transition: none;
                }
            }
        }
    }
}
