.block-about {
    --padding-v-top: var(--padding-xxl);
    --padding-v-bottom: var(--padding-xxxxl);
    --padding-h: var(--padding-s);
    --gap: var(--padding-xs);
    --benefit: #{rem(340)};
    --benefit-padding: var(--padding-small);
    --font-size: var(--font-size-large);
    
    @media (max-width: $smartphone) {
        --padding-v-top: calc(var(--header-height) + var(--padding-s));
        --padding-v-bottom: var(--padding-m);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxs);
        --benefit: #{rem(175)};
        --benefit-padding: var(--padding-xs);
        --font-size: var(--font-size-base);
    }
}

.block-about {
    padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    min-height: 100vh;

    align-items: center;
    justify-content: center;
    display: flex;
    gap: var(--gap);

    .benefit {
        @include font-sans(1.4);
        font-size: var(--font-size);

        border-radius: var(--radius);
        border: var(--border) solid var(--color);
        height: var(--benefit);
        width: var(--benefit);
        padding: var(--benefit-padding);
        position: relative;

        &:first-child {
            --color: var(--aux-1);
        }
        
        &:nth-child(2) {
            --color: var(--aux-2);
        }
        
        &:last-child {
            --color: var(--aux-3);
        }

        .label,
        .number {
            color: var(--color);
        }

        .number {
            position: absolute;
            right: var(--benefit-padding);
            bottom: var(--benefit-padding);
        }

        .text {
            @include font-serif();  
            color: var(--primary-color);
        }
    }

    @media (max-width: $smartphone) {
        flex-direction: column;
    }
    
    @media (min-width: $smartphone) {
        min-height: 150vh;
        // margin-bottom: var(--padding-xl);

        .benefit {
            // &:first-child {
            //     margin-top: 20vh;
            // }
            
            &:nth-child(2) {
                margin-top: 30vh;
            }
            
            &:last-child {
                margin-top: 60vh;
            }
        }
    }
}
