.scrollbar {
    // border: 1px solid var(--primary-color);
    border-radius: var(--scrollbar-height);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: var(--scrollbar-width);
    width: var(--scrollbar-height);
    backdrop-filter: blur(3px); 

    &[data-axis-y] {
        height: #{rem(360)};
        width: #{rem(5)};

        .thumb {
            transform-origin: center bottom;
            transform: scale(1.1, 0);
        }

        .track {
            background-color: rgba(0, 0, 0, .1);
        }
    }

    &[data-axis-x] {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);

        .thumb {
            transform-origin: 0 0;
            transform: scale(0, 1.1);
        }
    }
    
    .track,
    .thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .track {
        background-color: rgba(255, 255, 255, .4);
    }

    .thumb {
        background-color: var(--aux-1);
        border-radius: var(--scrollbar-height);
    }
}