.project-info {
    --font-size-big: var(--font-size-large);
    --font-size: var(--font-size-base);
    --font-size-label: var(--font-size-xsmall);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-base);
        --font-size: var(--font-size-xsmall);
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
    }
}

.project-info {
    @include font-sans();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    
    .label {
        font-size: var(--font-size-label);
        text-transform: uppercase;
        margin-bottom: var(--padding-xxs);
    }
    
    .overview {
        font-size: var(--font-size-big);
    }
    
    &__lists {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--padding-xxxs);

        .col {
            > div:not(:last-child) {
                margin-bottom: var(--padding-xs);
            }
        }
    }

    @media (max-width: $smartphone) {
        &__text {
            margin-bottom: var(--padding-s);
        }
    }
    
    @media (min-width: $smartphone) {
        .label {
            margin-bottom: var(--padding-xs);
        }

        .wrapper {
            display: grid;
            grid-template-areas: "a a b";
            gap: var(--padding-s);
        }

        .services p:not(:last-child) {
            margin-bottom: var(--padding-xxxs);
        }

        &__text {
            grid-area: a;
        }
        
        &__lists {
            grid-area: b;
            gap: var(--padding-s);
        
            .col {
                > div:not(:last-child) {
                    margin-bottom: var(--padding-s);
                }
            }
        }
    }
}