.block-results {
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-base);
        --font-size: var(--font-size-xsmall);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
    }
}

.block-results {
    @include font-sans();
    font-size: var(--font-size-title);
    position: relative;
    padding: var(--padding-v) var(--padding-h);

    .title {
        @include font-sans();
        font-size: var(--font-size-title);
    }

    .result {
        color: var(--aux-1);
    }
            
    ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    li:nth-child(n + 3) {
        margin-top: var(--padding-xxs);
    }

    @media (max-width: $smartphone) {
        .title {
            margin-bottom: var(--padding-xs);
        }
    }
    
    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--padding-m);

        li:nth-child(n + 3) {
            margin-top: var(--padding-s);
        }
    }
}
