.billboard-project {
    --padding-v-top: calc(var(--header-height) + var(--padding-xs));
    --padding-v-bottom: var(--padding-s);
    --padding-h: var(--padding-xl);
    --gap: var(--padding-xs);
    --font-size: var(--font-size-xlarge);
    --height: 85vh;

    @media (max-width: $smartphone) {
        --padding-v-top: var(--padding-s);
        --padding-v-bottom: var(--padding-s);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xxs);
        --font-size: var(--font-size-base);
        --height: #{rem(535)};
    }
}

.billboard-project {
    height: var(--height);
    padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;

    .wrapper {
        @include font-sans();
        font-size: var(--font-size);
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            @include font-sans(1);
        }
        
        .client, .label {            
            @include font-serif(1);
        }

        > div {
            > * {
                max-width: rem(850);
            }
        }
        
        > div:last-child {
            margin-top: var(--padding-xs);
            align-items: flex-end;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    
    .wrapper,
    figure,
    img {
        height: 100%;
        width: 100%;
    }

    // .overlay {
    //     background-color: var(--black);
    //     left: 0;
    //     opacity: .3;
    //     position: absolute;
    //     top: 0;
    //     width: 100%;
    // }

    figure {
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;

        img {
            object-fit: cover;
        }
    }
}
