.block-how-i-work {
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-xsmall);
    --padding-v-top: var(--padding-xxl);
    --padding-v-bottom: var(--padding-xl);
    --padding-h: var(--padding-xxl);
    --gap: var(--padding-l);
    --color: var(--primary-color);
    
    .scrabble-letter {
        --color: var(--primary-color);
        --font-size-letter: var(--font-size-xxlarge);
        --font-size-letter-sub: var(--font-size-base);
        --letter-size: #{rem(215)};
    }
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-medium);
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-xs);
        --gap: var(--padding-xs);
        
        .scrabble-letter {
            --letter-size: #{rem(156)};
        }
    }
}

.block-how-i-work {
    @include font-sans();
    font-size: var(--font-size);

    position: relative;
    width: 100vw;

    .title {
        @include font-sans();
        font-size: var(--font-size-title);
        color: var(--aux-1);
        margin-bottom: var(--padding-xs);
    }

    .text {
        max-width: rem(475);

        p {
            margin-bottom: var(--padding-xs);
        }
    }

    .holder {
        display: flex;
        height: 100%;
        width: auto;
    }
    
    .slide {
        color: var(--color);
        align-items: center;
        display: flex;
        justify-content: center;
        transition: color .3s ease-out;
        pointer-events: none;
        
        .wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            gap: var(--padding-small);
        }

        &.--active {
            --color: var(--aux-1);
            
            .scrabble-letter {
                --color: var(--aux-1);
            }
        }
    }

    .slide,
    [data-scroll-sticky]:first-child {
        padding: var(--padding-v-top) var(--padding-h) var(--padding-v-bottom);
    }
    
    @media (max-width: $smartphone) {
        padding: var(--padding-v) 0;

        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .slide {
            &:first-child { display: none; }

            scroll-snap-align: start;
        }

        .slide,
        [data-scroll-sticky]:first-child {
            padding: 0 var(--padding-h);
        }

        [data-scroll-sticky]:first-child {
            margin-bottom: var(--padding-s);
        }
    }
    
    @media (min-width: $smartphone) {
        height: var(--height);
        
        > div {
            height: 100vh;
            position: relative;
            width: 100vw;
            overflow: hidden;
        }

        > div:first-child {
            height: 50vh;
            overflow: visible;
        }
        
        .holder {
            left: 0;
            position: absolute;
            top: 0;
        }

        .slide:first-child { width: 100vw; }

        .slide {
            .wrapper {
                margin-top: 20vh;
            }
        }
    }
}